import cardData from '../utils/cardData'

function Examples() {
  return (
    <section className='cards-section' >
        {cardData.map(card => {
            return (
                <a className='card' href={card.url} target="_blank" rel="noreferrer">
                    <div className="card--info-wrapper">
                        <div className="card--info">
                            <div className='card--title' >{card.title}</div>
                            <div className='card--text' >{card.text}</div>
                            {card.text2 && <div className='card--text' >{card.text2}</div>}
                            {card.text3 && <div className='card--text' >{card.text3}</div>}
                        </div>
                    </div>
                    <div>
                        <img className='card--image' src={card.image} alt={card.title} />
                    </div>
                </a>
            )
        })}
    </section>
  )
}

export default Examples

