import BlackjackImg from '../assets/Blackjack-web-app.png'
import ChromeImg from '../assets/Chrome-extension.png'
import CompanyImg from '../assets/Company-landing-page.png'
import FileUploadImg from '../assets/File-upload-app.png'
import RoomImg from '../assets/Room-homepage.png'
import MultistepForm from '../assets/multistep-form.png'

const cardData = [
    {
        title: "Multistep form",
        text: 'Responsive',
        text2: 'React, styled-components',
        image: MultistepForm,
        url: 'https://calm-muffin-95240e.netlify.app/'
    },
    {
        title: "Furniture company's homepage",
        text: 'Responsive',
        text2: 'Plain HTML, CSS, JavaScript',
        image: RoomImg,
        url: 'https://juozasj.github.io/room-homepage/'
    },
    {
        title: "Company's landing page",
        text: 'Responsive',
        text2: 'React',
        image: CompanyImg,
        url: 'https://fluffy-youtiao-bbc78b.netlify.app/'
    },
    {
        title: 'Files upload app',
        text: 'Responsive',
        text2: 'React, Material UI',
        image: FileUploadImg,
        url: 'https://fascinating-yeot-633d8f.netlify.app/'
    },
    {
        title: 'Chrome extension to save links',
        text: 'Responsive',
        text2: 'Plain HTML, CSS, JavaScript',
        note: `Note! This is webpage version, "Save tab" only works when extension opened from browser's extensions interface`,
        image: ChromeImg,
        url: 'https://juozasj.github.io/leads-tracker/'
    },
    {
        title: 'Blackjack game web app',
        text: 'Responsive',
        text2: 'Plain HTML, CSS, JavaScript',
        image: BlackjackImg,
        url: 'https://juozasj.github.io/Blackjack/'
    },
]

export default cardData