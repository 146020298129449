import LinkedInLogo from '../assets/LI-Logo.png'
import GitHubLogo from '../assets/GitHub_Logo.png'
import ReactIcon from '../assets/React-icon.svg'
import MUILogo from '../assets/material-ui-logo.svg'
import JSLogo from '../assets/javascript-logo.png'
import HTMLLogo from '../assets/HTML5_Badge.svg'
import FramerMotionLogo from '../assets/framer-motion.svg'
import CSSLogo from '../assets/CSS3_logo.svg'

function Header() {
  return (
    <div className="header" >
      <h1>Juozas Juodakys</h1>
      <h2 className='title2'>Front-end developer</h2>
      <h2 className='title3'>HTML | CSS | JavaScript | React | Next | Material UI | Framer Motion</h2>
      <div className="icon-bar">
        <div className="links">
          <a className='linkedin' href="https://www.linkedin.com/in/juozas-juodakys-096064213/"><img src={LinkedInLogo} alt="LinkedIn" target="_blank" rel="noreferrer"/></a>
          <a className='github' href="https://github.com/JuozasJ?tab=repositories"><img src={GitHubLogo} alt="GitHub" target="_blank" rel="noreferrer"/></a>
        </div>
        <div className="icons">
          <img src={HTMLLogo} alt="" style={{width: '2.8vw'}} />
          <img src={CSSLogo} alt="" style={{width: '2.8vw'}} />
          <div style={{display: 'grid', placeItems: 'center', margin: '0 -1.35vw'}}><img src={JSLogo} alt="" style={{width: '5.5vw'}} /></div>
          <img src={ReactIcon} alt="" style={{width: '3vw'}} />
          <img src={MUILogo} alt="" style={{width: '2.8vw'}} />
          <img src={FramerMotionLogo} alt="" style={{width: '2.5vw'}} />
        </div>
      </div>
    </div>
  )
}

export default Header