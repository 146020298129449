import Examples from './components/Examples'
import Header from './components/Header'
import Footer from './components/Footer'

function App() {
  return (
    <div className="app">
      <Header />
      <Examples />
      <Footer />
    </div>
  );
}

export default App
